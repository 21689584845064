'use client';
import Slider, { Settings } from 'react-slick';
import styles from './styles.module.scss';
import { useTranslation } from '@/hook';
import TestimonialItem from '../testimonial-item/testimonial-item.component';
import { Skeleton } from '@mui/material';
import Typography from '@/components/shared/typography/typography.component';
import {
    TestimonialArrowLeft,
    TestimonialArrowRight,
} from '../../../../../public/Images';
import { useRef } from 'react';
import { useGetTestimonialsQuery } from '@/hook/testimonial-api-slice';
import Image from 'next/image';

// @ts-ignore

export default function TestimonialSection() {
    const { t, IS_RTL } = useTranslation();
    const sliderRef = useRef<Slider>(null);
    const {
        data: dataTestimonials,
        // isLoading: isLoadingTestimonials,
        isError: isErrorTestimonials,
        isFetching: isFetchingTestimonials,
    } = useGetTestimonialsQuery();

    const sliderSettings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        rtl: IS_RTL,
        arrows: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    centerMode: true,
                    variableWidth: false,
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    arrows: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: true,
                    variableWidth: false,
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    arrows: false,
                },
            },
        ],
    };

    function CustomPrevArrow() {
        return (
            <div
                className={'testimonial_wraper'}
                onClick={() =>
                    IS_RTL
                        ? sliderRef?.current?.slickNext()
                        : sliderRef?.current?.slickPrev()
                }
            >
                {IS_RTL ? (
                    <Image
                        src={TestimonialArrowRight.src}
                        width={TestimonialArrowRight.width}
                        height={TestimonialArrowRight.height}
                        alt="next"
                    />
                ) : (
                    <Image
                        src={TestimonialArrowLeft.src}
                        width={TestimonialArrowLeft.width}
                        height={TestimonialArrowLeft.height}
                        alt="prev"
                    />
                )}
            </div>
        );
    }

    function CustomNextArrow() {
        return (
            <div
                onClick={() =>
                    IS_RTL
                        ? sliderRef?.current?.slickPrev()
                        : sliderRef?.current?.slickNext()
                }
            >
                {IS_RTL ? (
                    <Image
                        src={TestimonialArrowLeft.src}
                        width={TestimonialArrowLeft.width}
                        height={TestimonialArrowLeft.height}
                        alt="prev"
                    />
                ) : (
                    <Image
                        src={TestimonialArrowRight.src}
                        width={TestimonialArrowRight.width}
                        height={TestimonialArrowRight.height}
                        alt="next"
                    />
                )}
            </div>
        );
    }

    return (
        <div className={styles.sold_testimonial_section}>
            <Typography
                variant="h4SemiBold"
                className={styles.title}
                style={{ fontSize: '2.1rem' }}
            >
                {t('Trusted By')}
            </Typography>
            <Typography variant="body2" className={styles.subTitle}>
                {t('Happy Customers')}
                <br />
                {t('Exercitation')}
            </Typography>
            <div className={styles.testimonials}>
                {/*@ts-ignore*/}
                <Slider
                    {...sliderSettings}
                    ref={sliderRef}
                    className={IS_RTL ? 'slidersAr' : 'sliders'}
                >
                    {/*// @ts-ignore*/}
                    {isFetchingTestimonials || isErrorTestimonials
                        ? Array.from(Array(3).keys()).map((_, i) => (
                              <Skeleton variant="rectangular" key={i}>
                                  <TestimonialItem
                                      key={i}
                                      verbatim={'#'.repeat(300)}
                                      name={''}
                                      rating={0}
                                  />
                              </Skeleton>
                          ))
                        : dataTestimonials?.map((review, i) => (
                              <TestimonialItem
                                  key={i}
                                  verbatim={review.summary}
                                  name={review.customerName}
                                  rating={review.customerRating}
                              />
                          ))}
                </Slider>
            </div>
            <div className={styles.arrowContainer}>
                <CustomPrevArrow />
                <CustomNextArrow />
            </div>
        </div>
    );
}
