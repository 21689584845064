'use client';
import BadgeWithTooltip, {
    EBadgeWithTooltip,
} from '@/components/shared/badege-with-tooltip/badge-with-tooltip.component';

import Typography from '@/components/shared/typography/typography.component';

import Link from '@/components/WrappedLink';
import { useAnalytics } from '@/integrations';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { isValidUUid } from '@/utils/uuidV4.util';
import {
    CalendarCardIcon,
    imgPlaceholder,
    LimitedTimeOffer,
    Speedometer,
    TransmissionIcon,
    trendingDown,
} from '../../../../public/Images';
import myImageLoader from '@/image.local.loader';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '@/constants';
import { slugify } from '@/utils/filterUtil';
import { InfoOutlinedIcon } from '@/components/shared/car-details-card/reserved-tooltip.component';
import { numberWithCommas } from '@/utils';
import colors from '@/theme/colors.module.scss';
import { memo } from 'react';

interface TFlapperLineProps {
    febDiscount: number;
    hasDiscount: boolean;
    discountText: string;
    febDiscountText: string;
    extraClasses?: ` ${string} `;
}

type TFlapperLineInnerContentProps = TFlapperLineProps & {
    animationClass: string;
};
const FlapperLineInnerContent = ({
    febDiscount = 0,
    hasDiscount = false,
    animationClass = '',
    discountText,
    febDiscountText,
}: TFlapperLineInnerContentProps) => {
    return (
        <div
            className={`absolute top-0 right-0 en:left-0 flex flex-col ${animationClass}`}
        >
            {febDiscount > 0 && (
                <div className={'w-full  flex mx-2 gap-1'}>
                    <img
                        src={myImageLoader({
                            src: trendingDown.src,
                            width: 14,
                            height: 14,
                            quality: 50,
                        })}
                        alt={'trending prices'}
                        width={14}
                        height={14}
                        loading={'lazy'}
                        fetchPriority={'low'}
                        className={' pb-0.5 ar:-scale-x-100'}
                    />
                    <Typography className={'text-xs leading-[28px]'}>
                        {febDiscount}% {febDiscountText}
                    </Typography>
                </div>
            )}
            {hasDiscount && (
                <div className={'w-full flex mx-2 gap-1'}>
                    <img
                        src={myImageLoader({
                            src: LimitedTimeOffer.src,
                            width: 14,
                            height: 14,
                            quality: 50,
                        })}
                        alt={'trending prices'}
                        width={14}
                        height={14}
                        loading={'lazy'}
                        fetchPriority={'low'}
                        className={' pb-0.5 en:-scale-x-100'}
                    />
                    <Typography className={'text-xs leading-[28px]'}>
                        {discountText}
                    </Typography>
                </div>
            )}
        </div>
    );
};
export const FlapperLine = ({
    febDiscount = 0,
    hasDiscount = false,
    discountText,
    febDiscountText,
    extraClasses,
}: TFlapperLineProps) => {
    return (
        (hasDiscount || febDiscount > 0) && (
            <div
                className={`relative w-full bg-secondary-50 overflow-hidden h-[28px] ${extraClasses}`}
            >
                <FlapperLineInnerContent
                    febDiscount={febDiscount}
                    hasDiscount={hasDiscount}
                    discountText={discountText}
                    febDiscountText={febDiscountText}
                    animationClass={
                        febDiscount > 0 && hasDiscount
                            ? ' animate-twoLineFlap1 '
                            : ''
                    }
                />
                <FlapperLineInnerContent
                    febDiscount={febDiscount}
                    hasDiscount={hasDiscount}
                    discountText={discountText}
                    febDiscountText={febDiscountText}
                    animationClass={
                        febDiscount > 0 && hasDiscount
                            ? ' animate-twoLineFlap2 '
                            : ''
                    }
                />
            </div>
        )
    );
};

const CarDisplayCardClient = memo(
    ({
        id,
        cardTitle,
        carName,
        installmentPrice,
        totalPrice,
        kilometers,
        year,
        transmission,
        images,
        trim,
        isReserved,
        isPurchaseInProgress,
        make,
        model,
        sourceScreen,
        appliedFilter,
        appliedSort,
        position,
        discountPrice,
        isNewlyAdded,
        febDiscount = 0,
        useNextImage = false,
        carCardLocalizations,
        lang = AVAILABLE_LANGUAGES[DEFAULT_LANGUAGE],
    }: {
        id: string;
        cardTitle: string;
        carName: string;
        installmentPrice: number | string;
        totalPrice: number | string;
        kilometers: number | string;
        year: number;
        transmission: string;
        images: string[];
        trim: { ar: string; en: string };
        isReserved: boolean;
        isPurchaseInProgress: boolean;
        make: string;
        model: string;
        sourceScreen: string;
        appliedFilter: boolean;
        appliedSort: boolean;
        position: number;
        discountPrice: number;
        isNewlyAdded: boolean;
        febDiscount: number;
        useNextImage?: boolean;
        carCardLocalizations: Record<string, string>;
        lang: AVAILABLE_LANGUAGES;
    }) => {
        const IS_RTL = lang === AVAILABLE_LANGUAGES.ar;
        const router = useRouter();

        const { trackEvent } = useAnalytics();

        const mainPrice = {
            color: '#009B5D',
            lineHeight: '32px',
            opacity: 1,
        };
        const mainPriceWithDsicount = {
            // textDecoration: 'line-through',
            fontSize: '0.75rem',
        };

        function goToCarPage() {
            trackEvent('Action: Click on Car Card', {
                carName,
                make,
                model,
                year,
                trim,
                kilometers,
                price: totalPrice,
                monthlyInstallment: installmentPrice,
                status: isPurchaseInProgress
                    ? 'Being Sold'
                    : isReserved
                    ? 'Reserved'
                    : 'Not Reserved',
                transmission,
                sourceScreen,
                appliedFilter,
                appliedSort,
                position,
                newlyAdded:
                    isNewlyAdded && !(isReserved || isPurchaseInProgress),
            });
            if (isValidUUid(id)) {
                router.push(`/en/car-details/${id}`);
            }

            // window.location.reload();
        }

        const offText = carCardLocalizations['Off'];
        const kText = carCardLocalizations['K'];

        const formatNumber = (number: number) => {
            const abbreviations = {
                K: 1000,
                M: 1000000,
            };

            const keys = Object.keys(abbreviations).reverse();

            for (const key of keys) {
                if (
                    number >= abbreviations[key as keyof typeof abbreviations]
                ) {
                    const resultNumber = (
                        number /
                        abbreviations[key as keyof typeof abbreviations]
                    ).toFixed(0);
                    return IS_RTL
                        ? `${offText} ${resultNumber} ${kText}`
                        : `${resultNumber}${kText} ${offText} `;
                }
            }

            return number.toString();
        };
        const hasDiscount = discountPrice !== undefined && discountPrice !== 0;
        const differencs = Math.trunc(
            parseFloat(totalPrice as string) - discountPrice
        );

        const discountPercentage = formatNumber(differencs);

        const CardBadge = () => {
            return (
                <>
                    {isPurchaseInProgress || isReserved ? (
                        <div
                            className={
                                'absolute z-[2] top-2 ar:left-2 ar:right-[unset] en:right-2 en:left-[unset]'
                            }
                        >
                            <BadgeWithTooltip
                                translations={carCardLocalizations}
                                type={
                                    isPurchaseInProgress
                                        ? EBadgeWithTooltip.PURCHASE_IN_PROGRESS
                                        : EBadgeWithTooltip.RESERVED
                                }
                            />
                        </div>
                    ) : (
                        isNewlyAdded && (
                            <div
                                className={
                                    'absolute z-[2] w-[70px] h-[47px] nw-tag top-2 right-0 left-[unset] en:right-[unset] en:left-0 '
                                }
                            >
                                {' '}
                            </div>
                        )
                    )}
                </>
            );
        };

        return (
            isValidUUid(id) && (
                <Link
                    prefetch={false}
                    className={`link-car-card ${
                        hasDiscount ? 'has-discount' : 'no-discount'
                    }`}
                    href={`/${lang}/car-details/${id}`}
                    onClick={() => goToCarPage()}
                >
                    <CardBadge />
                    <div
                        className={
                            ' aspect-[4/3] h-[calc(((100vw_-_32px)_/_4*_3))] md:h-auto w-full rounded-lg bg-contain '
                        }
                        style={{
                            backgroundImage: `url(${imgPlaceholder.src})`,
                        }}
                    >
                        {/* <CarouselV2
                            showDots={false}
                            autoPlay={false}
                            frameDuration={1000}
                        >
                            {useNextImage ? (
                                <div
                                    key={slugify(images[0])}
                                    className={'w-full h-full bg-contain'}
                                >
                                    <Image
                                        className={
                                            'w-full h-full max-h-full object-cover rounded-xl rounded-b-none'
                                        }
                                        width={320}
                                        height={240}
                                        quality={100}
                                        src={images[0]}
                                        alt={cardTitle}
                                        placeholder={'empty'}
                                        loading={'eager'}
                                        priority={true}
                                        fetchPriority={'high'}
                                    />
                                </div>
                            ) : (
                                <div
                                    key={slugify(images[0])}
                                    className={'w-full h-full bg-contain'}
                                >
                                    <img
                                        className={
                                            'w-full h-full max-h-full object-cover rounded-xl rounded-b-none'
                                        }
                                        width={320}
                                        height={240}
                                        src={myImageLoader({
                                            src: images[0],
                                            width: 320 * 4,
                                            height: 240 * 4,
                                            quality: 100,
                                        })}
                                        alt={cardTitle}
                                        loading={'eager'}
                                        fetchPriority={'high'}
                                    />
                                </div>
                            )}

                            {images.slice(1, 1).map(image => (
                                <div
                                    key={slugify(image)}
                                    className={'w-full h-full bg-contain'}
                                >
                                    <img
                                        className={
                                            'w-full h-full max-h-full object-cover rounded-xl rounded-b-none'
                                        }
                                        width={320}
                                        height={240}
                                        src={myImageLoader({
                                            src: image,
                                            width: 320,
                                            height: 240,
                                            quality: 100,
                                        })}
                                        alt={cardTitle}
                                        // loading={'lazy'}
                                        fetchPriority={'low'}
                                    />
                                </div>
                            ))}
                        </CarouselV2> */}
                        {useNextImage ? (
                            <div
                                key={slugify(images[0])}
                                className={'w-full h-full bg-contain'}
                            >
                                <Image
                                    className={
                                        'w-full h-full max-h-full object-cover rounded-xl rounded-b-none'
                                    }
                                    width={320}
                                    height={240}
                                    quality={100}
                                    src={images[0]}
                                    alt={cardTitle}
                                    placeholder={'empty'}
                                    loading={'eager'}
                                    priority={true}
                                    fetchPriority={'high'}
                                />
                            </div>
                        ) : (
                            <div
                                key={slugify(images[0])}
                                className={'w-full h-full bg-contain'}
                            >
                                <img
                                    className={
                                        'w-full h-full max-h-full object-cover rounded-xl rounded-b-none'
                                    }
                                    width={320}
                                    height={240}
                                    src={myImageLoader({
                                        src: images[0],
                                        width: 320 * 2,
                                        height: 240 * 2,
                                        quality: 100,
                                    })}
                                    alt={cardTitle}
                                    loading={'eager'}
                                    fetchPriority={'high'}
                                />
                            </div>
                        )}
                    </div>
                    <FlapperLine
                        hasDiscount={hasDiscount}
                        discountText={carCardLocalizations['limitedOffer']}
                        febDiscount={febDiscount}
                        febDiscountText={carCardLocalizations['FEB_DISCOUNT']}
                    />

                    <div className={'car-card-container '}>
                        <Typography
                            className={'car-card-title'}
                            variant={'body1Medium'}
                        >
                            {cardTitle}
                        </Typography>
                        <Typography
                            className={'car-card-trim-container'}
                            variant={'body2'}
                        >
                            {IS_RTL ? trim?.ar : trim?.en}
                        </Typography>
                        {/* price details part */}
                        <div className={'car-card-info-container'}>
                            <div style={{ display: 'flex' }}>
                                <Typography
                                    variant={'caption'}
                                    className={
                                        lang === 'ar'
                                            ? 'strikethroughAr'
                                            : 'strikethrough'
                                    }
                                    style={{
                                        fontWeight: '600',
                                        marginInline: '2px',
                                        fontSize: '1rem',
                                        color: '#B54708',
                                    }}
                                >
                                    {`${numberWithCommas(totalPrice)}`}
                                </Typography>
                                <Typography
                                    style={{
                                        marginInline: '2px',
                                        color: '#B54708',
                                    }}
                                    variant={'caption'}
                                >
                                    {carCardLocalizations['EGP']}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#FFFAEB',
                                    padding: ' 4px 10px 4px 10px',
                                    borderRadius: '24px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                    width: 'max-content',
                                    margin: '4px',
                                }}
                            >
                                <Typography
                                    style={
                                        hasDiscount
                                            ? {
                                                  ...mainPrice,
                                                  ...mainPriceWithDsicount,
                                                  lineHeight: '1.25rem',
                                                  color: '#B54708',
                                              }
                                            : mainPrice
                                    }
                                    variant={'subtitle1SemiBold'}
                                >
                                    {`${discountPercentage}`}
                                </Typography>
                            </div>
                        </div>
                        <div
                            className={'flex items-baseline'}
                            style={{
                                justifyContent: 'space-between',
                                marginTop: 'auto',
                            }}
                        >
                            <div className={'flex items-baseline'}>
                                <div
                                    className={
                                        'has-discount:flex no-discount:hidden items-baseline'
                                    }
                                >
                                    <Typography
                                        className={
                                            'text-primary-600 leading-8 opacity-100'
                                        }
                                        variant={'subtitle1SemiBold'}
                                    >
                                        {numberWithCommas(discountPrice)}
                                    </Typography>
                                    <Typography
                                        color="#667085"
                                        style={{ marginInline: '4px' }}
                                        variant={'caption'}
                                    >
                                        {carCardLocalizations['EGP']}
                                    </Typography>
                                </div>
                                <div
                                    className={
                                        'no-discount:flex has-discount:hidden items-baseline'
                                    }
                                >
                                    <Typography
                                        color="#009B5D"
                                        variant={'subtitle1SemiBold'}
                                    >
                                        {numberWithCommas(totalPrice)}
                                    </Typography>
                                    <Typography
                                        color="#667085"
                                        style={{ marginInline: '4px' }}
                                        variant={'caption'}
                                    >
                                        {carCardLocalizations['EGP']}
                                    </Typography>
                                </div>
                            </div>
                            {installmentPrice && (
                                <Typography
                                    color="#002D5D"
                                    variant={'body2SemiBold'}
                                >
                                    {carCardLocalizations['from']}{' '}
                                    {numberWithCommas(installmentPrice)}
                                    {carCardLocalizations['/month']}
                                </Typography>
                            )}
                        </div>
                        {/* more details part */}
                        <div
                            className={'flex items-center flex-wrap gap-2 mt-2'}
                        >
                            <div
                                className={'flex gap-4  items-center lg:gap-1'}
                            >
                                <img
                                    width={20}
                                    height={20}
                                    src={myImageLoader({
                                        src: Speedometer.src,
                                        width: 20,
                                        height: 20,
                                    })}
                                    className={'object-contain'}
                                    alt="kilometers"
                                />

                                <Typography color="#667085" variant={'body2'}>
                                    {numberWithCommas(kilometers)}{' '}
                                    {carCardLocalizations['km']}
                                </Typography>
                            </div>
                            <div
                                className={'flex gap-4  items-center lg:gap-1'}
                            >
                                <img
                                    width={20}
                                    height={20}
                                    src={myImageLoader({
                                        src: CalendarCardIcon.src,
                                        width: 20,
                                        height: 20,
                                    })}
                                    alt="year"
                                    className={'object-contain'}
                                />
                                <Typography color="#667085" variant={'body2'}>
                                    {year}
                                </Typography>
                            </div>
                            <div
                                className={'flex gap-4  items-center lg:gap-1'}
                            >
                                <img
                                    width={20}
                                    height={20}
                                    src={myImageLoader({
                                        src: TransmissionIcon.src,
                                        width: 20,
                                        height: 20,
                                    })}
                                    className={'object-contain'}
                                    alt="transmission"
                                />
                                <Typography color="#667085" variant={'body2'}>
                                    {transmission === 'automatic'
                                        ? carCardLocalizations['automatic']
                                        : carCardLocalizations['manual']}
                                </Typography>
                            </div>
                        </div>
                        {/* reserve info on mobile */}
                        {(isPurchaseInProgress || isReserved) && (
                            <div
                                className={`flex flex-row items-start gap-1.5 justify-center mt-2.5 p-2 rounded-lg  md:hidden ${
                                    isPurchaseInProgress && ' bg-blue-dark-25'
                                }
                            ${isReserved && ' bg-gray-100'}
                            `}
                            >
                                <InfoOutlinedIcon
                                    fontSize={'small'}
                                    color={'secondary'}
                                />
                                <Typography
                                    color={
                                        isPurchaseInProgress
                                            ? colors.secondary
                                            : colors.gray600
                                    }
                                    style={{ textAlign: 'start' }}
                                    variant="captionMedium"
                                >
                                    {isPurchaseInProgress
                                        ? carCardLocalizations[
                                              'purchaseInProgress.descriptionLong'
                                          ]
                                        : carCardLocalizations[
                                              'reservedBadgeTooltipDescription'
                                          ]}
                                </Typography>
                            </div>
                        )}
                    </div>
                </Link>
            )
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.id === nextProps.id &&
            prevProps.isNewlyAdded === nextProps.isNewlyAdded &&
            prevProps.isPurchaseInProgress === nextProps.isPurchaseInProgress &&
            prevProps.isReserved === nextProps.isReserved &&
            prevProps.febDiscount === nextProps.febDiscount &&
            prevProps.discountPrice === nextProps.discountPrice &&
            prevProps.totalPrice === nextProps.totalPrice &&
            prevProps.appliedFilter === nextProps.appliedFilter &&
            prevProps.appliedSort === nextProps.appliedSort &&
            prevProps.position === nextProps.position
        );
    }
);
CarDisplayCardClient.displayName = 'CarDisplayCardClient';
export default CarDisplayCardClient;
