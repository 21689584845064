'use client';
import Slider, { Settings } from 'react-slick';
import styles from './styles.module.scss';
import { useTranslation } from '@/hook';
import TestimonialItem from '../testimonial-item/testimonial-item.component';
import { Skeleton } from '@mui/material';
import { useRef } from 'react';
import { SourceScreen } from '@/types/source-screens.types';
import { Testimonial } from '@/types';

interface ITestimonialSectionProps {
    sourceScreen: string;
    testimonials: Testimonial[];
}

export default function TestimonialSection({
    sourceScreen,
    testimonials,
}: ITestimonialSectionProps) {
    const { IS_RTL } = useTranslation();
    const sliderRef = useRef<Slider>(null);

    const sliderSettings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        rtl: IS_RTL,
        arrows: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    centerMode: true,
                    variableWidth: false,
                    slidesToShow:
                        sourceScreen === SourceScreen.CAR_LISTING ? 2 : 3,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    arrows: false,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    centerMode: true,
                    variableWidth: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    arrows: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    centerMode: true,
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 10000,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <div className={styles.sold_testimonial_section}>
            <div className={styles.testimonials}>
                {/*@ts-ignore*/}
                <Slider
                    {...sliderSettings}
                    ref={sliderRef}
                    className={IS_RTL ? 'slidersAr' : 'sliders'}
                >
                    {testimonials.length === 0
                        ? Array.from(Array(3).keys()).map((_, i) => (
                              <Skeleton variant="rectangular" key={i}>
                                  <TestimonialItem
                                      key={i}
                                      verbatim={'#'.repeat(300)}
                                      name={''}
                                      rating={0}
                                  />
                              </Skeleton>
                          ))
                        : testimonials.map(review => (
                              <TestimonialItem
                                  key={`${review.customerName}${review.summary}`}
                                  verbatim={review.summary}
                                  name={review.customerName}
                                  rating={review.customerRating}
                              />
                          ))}
                </Slider>
            </div>
        </div>
    );
}
